import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import { Helmet } from 'react-helmet';
import BannerSlider from './Bannerslider';
import Insta from './insta';
import ExploreCollection from './ExploreCollection';
import Customer from './Customer';
import AOS from 'aos';
import 'aos/dist/aos.css' ;
import { useSelector } from 'react-redux';
import { client_name } from "../../Helpers/request";
import LooseCreateDiamond from "./LooseCreateDiamond";
import EarNeck from "./EarNeck";
import JewelryRepair from "./JewelryRepair";
import BrandLogos from "./BrandLogos";
import Testimonial from "./Testimonial";

const Home = () => {
  const metaDetails = useSelector(state => state.persistedReducer.meta.meta)

  useEffect(() => {
    AOS.init({
      duration : 1000,
      // disable: 'mobile'
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails.title}</title>
        <meta name="description" content={metaDetails.description}></meta>
        <meta name="keywords" content={metaDetails.keywords}></meta>
        {/*Og Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaDetails.title} />
        <meta property="og:description" content={metaDetails.description} />
        <meta property="og:url" content={metaDetails.url} />
        <meta property="og:site_name" content={client_name.c_name} />
      </Helmet>

      <BannerSlider />
      <ExploreCollection />
      <LooseCreateDiamond/>
      <BrandLogos/>
      {/* <EarNeck/> */}
      <JewelryRepair />
      {/* <Customer /> */}
      <Testimonial/>
      <Insta />
  
    </>
  )
}

export default Home;